import React from "react"

import { Helmet } from "react-helmet"

export default () => (
  <Helmet htmlAttributes={{ lang: "de" }}>
    {/* Speed optimization */}
    <link rel="preconnect" href="https://www.google-analytics.com" />

    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1"
    ></meta>
    <meta property="og:title" content="FLAGincluded - Home"></meta>
    <meta property="og:site_name" content="FLAGincluded"></meta>
    <meta property="og:type" content="website"></meta>
    <meta property="og:url" content="https://flagincluded.at"></meta>
    <meta property="og:image" content="assets/logos/logo_vert.png"></meta>
    <meta property="og:locale" content="de_AT"></meta>
    <meta
      property="og:description"
      content="Wir arbeiten mit Schulen in ganz Österreich zusammen, um während des Pride-Months LGBTIQ-Flaggen zu hissen."
    ></meta>
    <meta
      name="description"
      content="Wir arbeiten mit Schulen in ganz Österreich zusammen, um während des Pride-Months LGBTIQ-Flaggen zu hissen."
    ></meta>
  </Helmet>
)
