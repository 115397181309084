import React from "react"
import * as styles from "./footer.module.scss"

import { Link } from "gatsby"
import { OutboundLink } from "react-ga"

import Logo from "../images/logos/logo_vert.inline.svg"
import RainbowBar from "./rainbow-bar"
import Wrapper from "./wrapper"

var date = new Date()
var year = date.getFullYear()

export default props => (
  <div className={styles.footer} {...props}>
    <RainbowBar style={{ width: "100%", height: "5px", margin: 0 }} />
    <Wrapper>
      <Logo className={styles.footerLogo} />
      <div className={styles.footerContent}>
        <div className={styles.footerInfo}>
          <table>
            <tr>
              <td>Adresse</td>
              <td>Heumühlgasse 14/1, 1040 Wien</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>
                <OutboundLink
                  eventLabel="contact email"
                  to="mailto:kontakt@flagincluded.at"
                  className="greyLink"
                >
                  kontakt@flagincluded.at
                </OutboundLink>
              </td>
            </tr>
            {/*
            // TODO: bring back phone number when it is safe to do so
            <tr>
              <td>Telefon</td>
              <td>
                <OutboundLink
                  eventLabel="phone number"
                  to="tel:00436605077849"
                  className="greyLink"
                >
                  +43 660 5077849
                </OutboundLink>
              </td>
            </tr>
            */}
            <tr>
              <td>ZVR-&#8470;</td>
              <td>524534408</td>
            </tr>
          </table>
        </div>
        <div className={styles.footerLinks}>
          <Link to="/spenden">Spenden</Link>
          <Link to="/downloads">Downloads</Link>
          <Link to="/anmeldung">Schulanmeldung</Link>
          <hr />
          <OutboundLink
            eventLabel="https://www.instagram.com/flagincluded"
            to="https://www.instagram.com/flagincluded"
            target="_blank"
          >
            Instagram
          </OutboundLink>
        </div>
      </div>
      <div className={styles.footerLegal}>
        <div>
          <Link to="/impressum">Impressum</Link>
          {/* TODO: create gdpr page
          <div className={styles.seperator}></div> 
          <Link to="/datenschutz">Datenschutz</Link>
          */}
        </div>
        <div>
          <p>
            Ein Projekt der{" "}
            <OutboundLink
              eventLabel="https://www.hosiwien.at/"
              to="https://www.hosiwien.at/"
              target="_blank"
            >
              <span className="greyLink">HOSI Wien</span>
            </OutboundLink>
            .
            <br />
            Website gemacht mit ❤️ von Michi und Kilian.
            <br />
            ©FLAGincluded {year}. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </Wrapper>
  </div>
)
