import React from "react"
import { Link } from "gatsby"
import * as styles from "./header.module.scss"

import Logo from "../images/logos/logo_vert.inline.svg"

export default ({ button, ...otherProps }) => (
  <div className={styles.header} {...otherProps}>
    <div className={styles.logoContainer}>
      <Link className={styles.logoLink} to="/">
        <Logo className={styles.logo}></Logo>
      </Link>
    </div>
    {button}
  </div>
)
