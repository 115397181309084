import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { OutboundLink } from "react-ga"
import * as styles from "./rainbow-link.module.scss"

export const InternalRainbowLink = ({
  title,
  href,
  desc,
  fillWidth = true,
  ...otherProps
}) => (
  <Link
    to={href}
    className={classNames(
      styles.box,
      fillWidth && styles.fillWidthOnSmallScreen
    )}
    {...otherProps}
  >
    <div className={styles.innerBox}>
      <h3 className={styles.title}>{title}</h3>
      {desc && <p className={styles.desc}>{desc}</p>}
    </div>
  </Link>
)

export const ExternalRainbowlink = ({
  title,
  href,
  desc,
  fillWidth = true,
  ...otherProps
}) => (
  <OutboundLink
    eventLabel={href}
    to={href}
    className={classNames(
      styles.box,
      fillWidth && styles.fillWidthOnSmallScreen
    )}
    {...otherProps}
  >
    <div className={styles.innerBox}>
      <h3 className={styles.title}>{title}</h3>
      {desc && <p className={styles.desc}>{desc}</p>}
    </div>
  </OutboundLink>
)
