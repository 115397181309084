import React from "react"
import * as styles from "./alert.module.scss"

import ErrorIcon from "../images/icons/error.inline.svg"
import InfoIcon from "../images/icons/info.inline.svg"
import SuccessIcon from "../images/icons/success.inline.svg"

export default ({ message, options, style, close }) => {
  return (
    <div className={styles.alertBox} style={{ ...style }}>
      <div className={styles.alertContent}>
        {options.type === "error" && <ErrorIcon className={styles.icon} />}
        {options.type === "info" && <InfoIcon className={styles.infoIcon} />}
        {options.type === "success" && <SuccessIcon className={styles.icon} />}
        {options.type === "error" && (
          <p className={styles.errorMessage}>{message}</p>
        )}
        {options.type === "info" && (
          <p className={styles.infoMessage}>{message}</p>
        )}
        {options.type === "success" && (
          <p className={styles.successMessage}>{message}</p>
        )}
      </div>
      <div
        onClick={close}
        onKeyDown={close}
        role="button"
        tabIndex={0}
        className={styles.closeX}
      ></div>
    </div>
  )
}
