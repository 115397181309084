// extracted by mini-css-extract-plugin
export var alertBox = "alert-module--alertBox--7vPBR";
export var alertBoxa = "alert-module--alert-boxa---UnQP";
export var alertBoxb = "alert-module--alert-boxb--NN74O";
export var alertContent = "alert-module--alertContent--X2LS-";
export var closeX = "alert-module--closeX--y6nqN";
export var errorMessage = "alert-module--errorMessage--HV20s";
export var icon = "alert-module--icon--nKypv";
export var infoIcon = "alert-module--infoIcon--zcMWg";
export var infoMessage = "alert-module--infoMessage--SvPum";
export var successMessage = "alert-module--successMessage--JvZ9N";