import React, { Fragment, useEffect, useCallback } from "react"

import { Helmet } from "react-helmet"
import SEO from "./seo"

import {
  transitions,
  positions,
  Provider as AlertProvider,
  useAlert,
} from "react-alert"
import AlertTemplate from "../components/alert"

function PrivacyPopup({ appearanceDelay = 10000 }) {
  const alert = useAlert()

  const showAlert = useCallback(() => {
    alert.show(
      "Danke, dass du unsere Website besuchst! Um die Erfahrung für alle Besucher zu verbessern, sammeln wir anonymisierte Nutzungsdaten. Wir benutzen diese nur für die Optimierung der Website und teilen sie mit keiner dritten Partei.",
      {
        type: "info",
        timeout: 0,
        onClose: () => localStorage.setItem("privacy-popup", "shown"),
      }
    )
  }, [alert])

  useEffect(() => {
    const handle = setTimeout(() => {
      const privacyPopUpShown =
        typeof window !== "undefined" &&
        localStorage.getItem("privacy-popup") === "shown"

      if (!privacyPopUpShown) {
        showAlert()
      }
    }, appearanceDelay)

    return () => clearTimeout(handle)
  }, [appearanceDelay, showAlert])

  return <Fragment />
}

export default ({ children, title }) => (
  <Fragment>
    <SEO />
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <AlertProvider
      template={AlertTemplate}
      position={positions.BOTTOM_CENTER}
      timeout={5000}
      offset={"20px"}
      transition={transitions.SCALE}
    >
      <PrivacyPopup />
      {children}
    </AlertProvider>
  </Fragment>
)
